<template>
<div>
    <select name="salutation" @change="updateSalutation">
        <option value="">----</option>
        <option v-for="item of salutations" :value="item" :key="item" :select="salutation===item">{{item}}</option>
    </select>
    <input type="text" :value="name" @input="updateName" @change="updateName" />
</div>
  
</template>

<script>
const salutations =[
    "Ms",
    "Mrs",
    "Miss",
    "Mx",
    "Dr",
]
export default {
    name:"salution-name",
    props: {
        salutation:{
            type:String,
            default:""
        },
        salutationModifilers:{
            default: ()=>{},
            type: Object
        },
        name:{
            type:String,
            default:"",
        },
        nameModifilers:{
            default: ()=>({}),
            type: Object,
        }
    },
    setup(props,{emit}){
        const updateSalutation = envet => {
            let val= envet.target.value;
            // if(props.salutationModifilers.capitalize){
            //     val=val.toupperCase();
            // }
            emit("update:salutation",val);
        };

        const updateName = envent =>{
            let val=envent.target.value;
            // if(props.nameModifilers.capitalize){
            //     val = val.charAt(0).toUpperCase() + val.slice(1);            
            // }
            // if(props.nameModifilers.reverse && envent.type === "change"){
            //     val = val.split("").reverse().join("");
            // }
            emit("update:name",val);
        };
        return {salutations,updateSalutation,updateName}
    }

}
</script>

<style>

</style>